html {
    max-width: 500px;
    margin: 0 auto;
    position: relative;
}

.font-bold {
    font-weight: bold;
}

.emergency-button {
    color: white;
    background: #FF5733;
    border: #FF5733;
    box-shadow: 3px 3px 10px 0 #FF5733 !important;
    border-radius: 50% !important;
    transition: all 0s !important;
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 1000;
    width: 60px;
    height: 60px;
    font-size: 18px;
    outline: none !important;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

div.intl-tel-input > div.flag-container {
    padding: 0 10px 0 10px !important;
}

div#phoneContainer > div.separate-dial-code > input.customInput {
    font-size: .95rem;
    padding-left: 89px !important;
}

div.intl-tel-input > div.flag-container > div.selected-flag:hover,
div.intl-tel-input > div.flag-container > div.selected-flag:focus,
div.intl-tel-input > div.flag-container > div.selected-flag:active,
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
    border: none;
    outline: none;
    background-color: transparent !important
}

div.logout-btn-container {
    width: 100%;
}

    div.logout-btn-container > button {
        width: 70%;
        margin: 0 auto;
    }

.reportTable td,
.reportTable th {
    font-size: 10.5px !important;
}

.ul-news-list > li {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.ul-news-list li:not(:last-child) {
    border-bottom: unset !important;
}

.ul-news-list > li.announcementUnread {
    background-color: #efefef;
}

.ul-news-list > li:last-of-type {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.ul-news-list > li:first-of-type {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.rangeslider__handle > .rangeslider__handle-label,
.rangeslider__handle > .rangeslider__handle-tooltip {
    user-select: none;
}

.rangeslider .rangeslider__handle-tooltip {
    width: 50px;
}

.rangeslider__handle {
    left: 25px;
}

div.css-k1fzpy-control,
div.css-42kscm-control {
    padding-left: 15px;
}

.resultCell {
    text-align: center
}

.action-icon-container {
    padding: 0 20px !important;
}

    .action-icon-container > p {
        display: flex;
        flex: 0.8;
    }

    .action-icon-container > div {
        display: flex;
        flex: 0.2;
    }

.game-image {
    height: 65px;
    width: 65px;
    object-fit: contain;
    border-radius: 0.5rem;
}

.game-container {
    padding: unset !important;
}

.dashboard .game-container {
    max-width: 90px;
    height: 125px;
}

.game-row {
    margin: 0 auto;
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
}

.game-container > a {
    text-decoration: none;
}

.game-names > p {
    color: black;
    text-decoration: none;
}

.game-title {
    width: 100%
}

@media (max-width: 400px) {
    .game-row {
        padding-left: unset;
    }
}

.rdt .rdtPicker .rdtDays {
    min-width: 150px !important
}

.rdt .rdtPicker {
    right: auto !important
}

.loading-overlay {
    display: none;
}

._loading_overlay_wrapper--active.loading-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffffcf;
    z-index: 99;
}

._loading_overlay_overlay {
    display: none !important;
}

._loading_overlay_wrapper--active.loading-overlay p {
    color: #1c2a4f;
    margin-top: 1rem;
}

.loading-overlay.hide {
    display: none !important
}

.loading-overlay img {
    width: 50px;
}

.btn.btn-brand-gradient {
    border: none;
}

.pin-wrapper {
    height: 100vh;
    width: 100vw;
    background: black;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 0;
}

.pin-number {
    position: fixed;
    align-items: center;
    width: 100%;
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 30px;
    left: 0;
    bottom: 0;
    text-align: center;
}

#deposit > div > div > div,
#deposit > form > div > div > div > div,
#withdrawal > div > div > div,
#withdrawal > form > div > div > div > div {
    border: 0;
}

#deposit input::-webkit-input-placeholder,
#withdrawal input::-webkit-input-placeholder,
.deposit-part input::-webkit-input-placeholder,
.deposit-inner-panel input::-webkit-input-placeholder,
.internal-transfer input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: black;
    font-weight: normal;
}

.pool-result .pool-calendar .react-datepicker-wrapper input {
    font-size: 13px;
    color: #002e6c;
    background: #fff;
    border-radius: 12px !important;
}

.modal-content.edit-downline-modal {
    height: 85%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 10px 10px 0 10px;
}

    .modal-content.edit-downline-modal .modal-body {
        overflow: auto;
    }

    .modal-content.edit-downline-modal .modal-title {
        font-size: 16px;
    }


    .modal-content.edit-downline-modal .modal-header,
    .modal-content.edit-downline-modal .modal-body,
    .modal-content.edit-downline-modal .modal-footer {
        border: 0;
        color: #002e6c;
    }

.edit-downline-modal .form-group label {
    margin: 0;
}

.change-pin-label {
    padding-left: 20px;
    font-size: 16px;
    color: #002e6c;
}

.setting-options-icon-bg {
    width: 45px;
    height: 45px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F0F8FF;
    box-shadow: 0 2pt 4pt #ffc15940;
    border-radius: 1rem;
    margin-bottom: 8px;
}

input.pincode-input-text {
    width: 35px !important;
    height: 35px !important;
    background: transparent !important;
    border-radius: 0 !important;
    border-bottom: 2px solid #002e6c !important;
}

.content-header.home-header.shared-header.news-details {
    height: 100px;
    /*border-radius: 0 0 5px 5px;*/
}

.inbox-icon-cont {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    margin-top: -25px;
    background: linear-gradient(180deg, #FFCF7D, #FE9F00);
    border-radius: 100px;
    padding: 10px;
}

    .inbox-icon-cont > svg {
        width: 100% !important;
        height: 100% !important;
    }

.news-detail-title {
    text-align: center;
    color: #002e6c;
    font-size: 16px;
}

.login .lang-selector input {
    height: 30px;
}

.wallet-info .modal-body {
    padding: 1rem 2rem;
}

.forget-password .modal-body {
    max-height: 150px !important;
    min-height: 150px !important;
}

.owl-carousel {
    z-index: 0;
}

.job-title {
    width: 80%;
    border-right: 1px solid #d6dadd;
}

.job-button {
    text-align: center;
}

.banner-popup {
    position: fixed;
    align-items: center;
    width: 345px;
    height: 300px;
    background-color: white;
    padding: 20px;
    text-align: center;
    border-radius: 20px;
    margin: auto;
    inset: 0;
}

.banner-popup-wrapper {
    height: 100vh;
    width: 100vw;
    background: black;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 0;
}

.banner-popup-content {
    font-family: cursive;
    font-size: 15px;
    margin-top: 15px;
}

.banner-popup-image-wrapper {
    margin: auto;
}

.banner-popup-image {
    width: 100%;
}

.banner-popup-close-button {
    color: white;
    background-color: #002e6c;
    border: none;
    border-radius: 10px;
    width: 25%;
    height: 30px;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: auto;
}

.game-card {
    border-radius: 0;
    box-shadow: none;
}

.accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
    background-color: transparent;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 18px;
    color: #002E6C;
}

.accordion > .card:not(:last-of-type) {
    border-bottom: 1px solid #002E6C4D;
}

.card-header {
    border-bottom: 1px solid #002E6C4D;
}

.grid-wrapper {
    display: grid !important;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 2fr;
    grid-gap: .5rem;
}

.games-row {
    text-align: center;
}

.game-category-label {
    position: absolute;
    left: .2rem;
    font-size: 80%;
    top: .2rem;
    display: block;
    padding: .2rem .4rem;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: .05rem;
    text-shadow: 1px 1px 4px #000;
    background: rgba(22,36,56,.27);
    backdrop-filter: blur(4.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border-radius: 10px;
    border: 1px solid hsla(0,0%,100%,.18);
}

.game-info {
    line-height: .8;
    text-align: center;
    color: #252525;
    padding: 10px 5px;
    border: 1px solid #ffc159;
    border-radius: 15px;
    position: relative;
    max-width: 90px;
    height: 100px;
    width: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.game-name {
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 500;
    color: #002E6C;
    text-align: center;
}

.modal-body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: grey;
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: #002e6c;
}

.games-lobby {
    margin-bottom: 5px;
}

    .games-lobby .nav-item {
        font-family: 'Montserrat';
        color: #002e6c;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        width: 100px;
    }

        .games-lobby .nav-item.active {
            background-color: #fe9f00;
            border-radius: 25px;
        }

.carousel-left-right-btn {
    position: absolute;
    top: 115px;
    background-color: #002e6c;
    color: white;
    width: 13px;
    border-radius: 5px;
}

.carousel-img {
    width: 100%;
    height: 100vh;
    object-fit: contain;
}

.dashboard .carousel-img {
    height: 150px;
    display: block;
    object-fit: cover;
}

.full-screen-img {
    top: 155px;
    left: 0;
    position: absolute;
    width: 100%;
    height: 55%;
    padding: 15px;
}

.click-continue {
    position: absolute;
    left: 0;
    bottom: 50px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    opacity: 0.5;
}

.close-popup {
    position: fixed;
    top: 0;
    right: 0;
    color: white;
    margin: 55px 30px;
    font-size: 20px;
    z-index: 999;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

    .carousel-indicators li {
        box-sizing: content-box;
        flex: 0 1 auto;
        width: 9px;
        height: 9px;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #fff;
        background-clip: padding-box;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        opacity: .3;
        transition: opacity .6s ease;
        border-radius: 30px;
    }

.dashboard-carousel-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 40px 0;
}

.shareholder-dropdown {
    margin-left: 5px;
}

.nested-dropdown {
    float: right;
}

.badge-yes {
    background: linear-gradient(180deg, #b4f3d2, #0bf954);
    color: #002e6c;
}

.badge-no {
    background: linear-gradient(180deg, #f39eb1, #fd3f5e);
}

.badge-isShareHolder {
    background: linear-gradient(180deg, #FFCF7D, #FE9F00);
    color: #002e6c;
}

.card-package .table .child-row td {
    border-color: transparent !important;
}


    .card-package .table .child-row td.cat-prod {
        font-size: 9px !important;
    }

.card-package .table .subtotal td {
    background: #f0f8ff;
    border: none;
}

.card-package .table .grandtotal td {
    background: #f0f8ff;
    border: none;
}

.table-kpi-summary {
    color: #002e6c;
    font-weight: 600;
}

.summary-title {
    margin: 20px 0 20px 20px;
}

.claim-title {
    margin: 20px 0 20px 20px;
}

.category-product {
    padding-left: 20px !important;
}

.changePin-modal-header {
    text-align: center;
    color: #002e6c;
    font-size: 20px;
    font-weight: 600;
}

.nav-link {
    padding: 0.5rem 0;
}

.downline-nav .nav-item {
    flex: 1 1;
    text-align: center;
}

    .downline-nav .nav-item .nav-link.active {
        color: #ffc159 !important;
        background-color: transparent;
        border-bottom: 3px solid;
    }

.dashboard-top-right {
    display: block;
    color: white;
    font-weight: 100;
    font-size: 10px;
    margin-top: 5px;
}

.referral-category {
    margin: 4px;
    color: red;
}

.login-btn {
    color: white !important;
    text-transform: uppercase;
}

.login-bottom {
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 10px;
    font-size: 8px;
}

.login-bottom-left {
    float: left;
    margin-left: 67px;
}

.login-bottom-right {
    float: right;
    margin-right: 67px;
}

.login-browser-icon {
    width: 13px;
    margin: 5px 8px 0 0;
}

.downline-group-checkbox {
    align-self: center;
}

.input-checkbox-custom {
    display: none;
}

    .input-checkbox-custom ~ label .checked-icon {
        display: none;
        opacity: 0;
    }

    .input-checkbox-custom:checked ~ label .checked-icon {
        display: block;
        opacity: 1;
    }

    .input-checkbox-custom ~ label .unchecked-icon {
        display: block;
        opacity: 1;
    }

    .input-checkbox-custom:checked ~ label .unchecked-icon {
        display: none;
        opacity: 0;
    }

.tnc-container {
    position: absolute;
    text-align: center;
    padding: 25px 25px 75px 25px;
    overflow-y: scroll;
    height: calc(100% - 56px);
    color: #002C68;
    font-weight: bold;
}

    .tnc-container::-webkit-scrollbar {
        background-color: #efefef;
        width: 8px;
    }

    .tnc-container::-webkit-scrollbar-thumb {
        background-color: #002d6a;
    }

.tnc-logo {
    width: 100px;
    background-color: #002d6a;
    padding: 18px;
    border-radius: 10px;
}

.tnc-top-text {
    font-size: 20px;
    margin-top: 15px;
    font-weight: bold;
}

.tnc-content {
    text-align: justify;
    margin-top: 15px;
}

.tnc-label {
    border-bottom: 1px solid;
    margin-top: 20px;
    color: #002C68;
}

.tnc-list {
    display: flex;
}

.tnc-bullets-wrapper {
    margin-left: 50px;
}

.tnc-bullets {
    display: list-item;
}

.tnc-agree-label {
    display: inline-flex;
    color: white !important;
    padding-left: 0 !important;
    margin-top: 5px;
    white-space: break-spaces;
}

.tnc-popup-wrapper {
    height: 100vh;
    width: 100vw;
    background: black;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 0;
}

.tnc-popup-header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #002C68;
    margin-bottom: 15px;
}

.tnc-popup {
    position: fixed;
    align-items: center;
    width: 75vw;
    height: 75vh;
    background-color: white;
    padding: 20px 7px 20px 15px;
    left: 12vw;
    top: 15vh;
    border-radius: 20px;
    z-index: 2;
    display: grid;
}

.tnc-popup-body {
    overflow-y: scroll;
    overflow-x: clip;
    height: 50vh;
    margin-bottom: 10px;
    text-align: justify;
    font-size: 12px;
    color: #002C68;
    letter-spacing: 0.2px;
    padding-right: 8px;
}

    .tnc-popup-body::-webkit-scrollbar {
        background-color: #efefef;
        width: 8px;
    }

    .tnc-popup-body::-webkit-scrollbar-thumb {
        background-color: #002d6a;
    }

.tnc-popup-label {
    color: #FFC159;
    margin-top: 20px;
}

.top-banner .text-and-searchbar {
    width: 100%;
    text-align: center;
    position: absolute;
    margin-top: -120px;
    left: 0;
}

    .top-banner .text-and-searchbar .banner-text {
        font-size: 25px;
        text-transform: uppercase;
        color: white;
        margin-bottom: 10px;
    }

.banner-searchbar .search-box {
    background-color: white;
    width: 200px;
    height: 30px;
    border-radius: 20px;
    border: transparent;
    padding: 10px;
    font-size: 10px;
}

    .banner-searchbar .search-box:focus-visible {
        outline: transparent;
    }

    .banner-searchbar .search-box::placeholder {
        color: #002e6c;
    }

#hot-games-list {
    margin: 0 20px;
    justify-content: space-around;
}

.hot-games-label {
    margin: 12px 27px;
}

.hot-games-tile {
    text-align: -webkit-center;
    max-width: 100px;
}

.lobby-games-img {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
    margin: 5px 0px;
}

.hot-games-text {
    color: #002e6c;
    font-weight: bold;
    font-size: 12px;
    margin: 0 5px;
    overflow-wrap: break-word;
}

.all-games {
    margin-top: 20px;
}

.games-lobby .nav-item.active {
    background-color: rgba(254, 159, 0, 0.2);
    border-radius: 0;
    border-bottom: 2px solid rgb(254 159 0);
}

.all-games .tab-content {
    margin: 0 20px;
}

.banner-background {
    width: 100%;
    opacity: 0.4;
    min-height: 130px;
    height: 250px;
    background-size: cover;
    background-position: center center;
}

.banner-icon {
    width: 32px;
    height: 22px;
    margin: 20px auto;
    background-size: cover;
    background-position: center center;
}

.banner-icon-wrapper {
    position: absolute;
    top: 0;
    height: 25px;
    width: 100%;
}

.banner-close-btn {
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 25px;
    width: 30px;
    height: 30px;
    text-align: center;
}

.search-icon {
    position: absolute;
    margin: -22px 0px 0px 75px;
}

.btn-pregame-popup {
    width: 40%;
}

#dashboard-pregame-popup {
    margin: auto;
}

    #dashboard-pregame-popup .modal-pregame-popup {
        height: 100vh;
        background-color: #02285A;
        border: 0;
        border-radius: 0;
    }

    #dashboard-pregame-popup .pregame-popup-header {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: white;
    }

    #dashboard-pregame-popup .col-form-label {
        color: white;
    }

    #dashboard-pregame-popup .pregame-popup-banner {
        width: 100%;
        opacity: 0.7;
        height: 200px;
        background-size: cover;
        background-position: center center;
    }

    #dashboard-pregame-popup .wallet-info {
        display: flex;
        color: white;
        font-size: 15px;
        justify-content: space-evenly;
        padding: 10px;
        align-items: center;
        text-align: center;
    }

    #dashboard-pregame-popup .vertical-line {
        background-color: white;
        margin: 0;
    }

    #dashboard-pregame-popup .transfer-input {
        display: flex;
        justify-content: center;
        margin: 15px;
        color: #FFC159;
        flex-direction: column;
        text-align: center;
    }

        #dashboard-pregame-popup .transfer-input > * {
            align-self: center;
            margin: 0 auto !important;
        }

        #dashboard-pregame-popup .transfer-input .currency {
            margin-top: 12px;
        }

    #dashboard-pregame-popup .transfer-balance {
        display: flex;
        justify-content: space-between;
        margin: 0 20px;
    }

.transfer-amount-wrapper {
    color: white;
    margin: 20px 0;
}

.transfer-input-label {
    text-align: center;
}

.transfer-amount {
    background-color: rgba(255, 255, 255, 0.1) !important;
    box-shadow: none !important;
    width: 200px !important;
    overflow-x: hidden;
    margin: 15px !important;
    color: #FFC159;
    font-size: 30px;
    text-align: center;
    line-height: 30px;
}

    .transfer-amount:focus-visible {
        outline: 0;
    }

    .transfer-amount:hover {
        box-shadow: none !important;
    }

    .transfer-amount:focus {
        color: #FFC159;
        border-color: transparent;
    }

    .transfer-amount::placeholder {
        color: #FFC159 !important;
        font-size: 30px;
    }

.transfer-btn {
    text-align: center;
}

    .transfer-btn .btn {
        width: 125px;
        font-size: 15px;
    }

.bonus-and-commission {
    color: white;
    padding: 50px 25px 25px 25px;
}

    .bonus-and-commission .bonus {
        margin-bottom: 15px;
    }
    .bonus-and-commission .commission {
        margin-bottom: 15px;
    }

.start-btn {
    text-align: center;
}

.dashboard {
    overflow-y: scroll;
}

    .dashboard::-webkit-scrollbar {
        display: none;
    }

    .dashboard .games-menu {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 2fr;
    }

.user-name {
    color: white;
}

.user-info {
    display: flex;
    justify-content: space-between;
    height: 60px;
    color: white;
}

.wallet-and-commission {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 13px;
}

.user-info-right {
    display: flex;
    align-self: center;
    width: 200px;
    justify-content: space-between;
    align-items: flex-end;
}

    .user-info-right .text {
        display: block;
        color: #FFC159;
        font-weight: 100;
        font-size: 12px;
        margin-top: 5px;
    }

    .user-info-right .kpi-and-settings {
        display: grid;
        text-align: center;
        width: 55px;
        justify-items: center;
    }

    .user-info-right .referral .referral-icon {
        width: 22px;
        height: 22px;
        background-size: cover;
        background-position: center center;
    }

    .user-info-right .kpi .kpi-icon {
        width: 28px;
        height: 28px;
        background-size: cover;
        background-position: center center;
    }

    .user-info-right .contact-us .contact-us-icon {
        width: 28px;
        height: 28px;
        background-size: cover;
        background-position: center center;
    }

.dashboard-menu {
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: flex-end;
    text-align: center;
}

    .dashboard-menu .menu-name {
        color: white;
        margin-top: 6px;
        font-size: 12px;
        font-weight: bold;
    }

    .dashboard-menu .menu-icon {
        width: 25px;
        margin-top: 4px;
    }

.promo-banner-scroll {
    height: 120px;
    margin: 15px 0px;
    overflow-x: scroll;
    display: flex;
}

    .promo-banner-scroll::-webkit-scrollbar {
        display: none;
    }

    .promo-banner-scroll .promo-banner {
        margin-right: 20px;
    }

.games-content {
    display: flex;
    width: 100%;
    margin-top: 30px;
}

    .games-content::-webkit-scrollbar {
        display: none;
    }

    .games-content .sidenav-menu .category-tile {
        width: 70px;
        height: 70px;
        background-color: #FFC159;
        text-align: -webkit-center;
        padding-top: 12px;
        margin-bottom: 20px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .games-content .game-category-label {
        font-size: 55%;
    }

    .games-content .game-info {
        height: 100px;
        width: 100px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .games-content .sidenav-menu .nav-link.active {
        background-color: white;
    }

    .games-content .sidenav-menu .nav-item {
        justify-content: center;
        width: 75px;
    }

    .games-content .tab-content {
        height: 700px;
        width: 100%;
        overflow-y: scroll;
    }

        .games-content .tab-content::-webkit-scrollbar {
            width: 3px;
            height: 0;
            background-color: transparent;
        }

        .games-content .tab-content::-webkit-scrollbar-thumb {
            background-color: grey;
            border-radius: 10px;
        }

.category-list .category-icon {
    width: 30px;
    height: 25px;
    background-size: cover;
    background-position: center center;
}

.category-list .category-text {
    color: #002E6C;
    font-size: 11px;
    margin-top: 10px;
    font-weight: bold;
}

.games-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-items: center;
}

    .games-menu .game-name {
        color: white;
    }

.menu-link {
    max-width: 65px;
    text-align: center;
}

@media (max-width: 299px) {
    .dashboard .games-menu {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media (max-width: 321px) {
    .user-name {
        font-size: 12px;
    }

    .games-content .sidenav-menu .nav-item {
        width: 50px;
    }

    .games-content .sidenav-menu .category-tile {
        width: 48px;
        height: 48px;
        border-radius: 10px;
    }

    .category-list .category-icon {
        width: 22px;
        height: 22px;
    }

    .category-list .category-text {
        margin-top: 5px;
    }

    .menu-link {
        max-width: 50px !important;
    }

    .wallet-and-commission {
        font-size: 12px !important;
    }

    .user-info {
        height: 80px !important;
    }

    .user-info-right {
        width: 175px !important;
    }

        .user-info-right .text {
            font-size: 10px !important;
        }

        .user-info-right .kpi-and-settings {
            width: 50px !important;
        }

    .dashboard-menu .menu-name {
        font-size: 10px !important;
    }

    .bonusComm-option-select {
        width: 65px !important;
        font-size: 12px;
        margin: 0 5px !important;
    }
}

@media (max-width: 281px) {
    .games-content .game-info {
        height: 80px !important;
        width: 80px !important;
    }

    .dashboard .game-container {
        height: 90px !important;
    }

    .edit-percent-value {
        width: 40px !important;
    }
}

@media (max-width: 361px) {
    .games-content .game-info {
        height: 75px !important;
        width: 75px !important;
    }

    .payment-option-card .options .option .option-image {
        height: 45px !important;
        width: 45px !important;
        margin: 0 5px !important;
        border-radius: 5px !important;
        font-size: 10px;
    }

    .payment-option-card .options .option .option-text {
        font-size: 12px;
    }
}

@media (max-width: 374px) {
    .dashboard-v2-wrapper {
        padding: 10px !important;
    }

    .games-content .game-info {
        height: 80px;
        width: 80px;
    }
}

@media (max-width: 401px) {
    .dashboard-v2-wrapper {
        padding: 10px;
    }

    .games-content .sidenav-menu .nav-item {
        width: 60px;
    }

    .games-content .sidenav-menu .category-tile {
        width: 60px;
        height: 60px;
    }

    .category-list .category-icon {
        width: 25px;
        height: 22px;
    }

    .category-list .category-text {
        margin-top: 5px;
    }

    .games-content .game-info {
        height: 80px;
        width: 80px;
    }

    .dashboard .game-container {
        height: 95px;
    }

    .edit-percent-value {
        width: 40px !important;
    }

    .row-data-1 {
        font-size: 12px !important;
    }

    .row-data-2 {
        width: 80% !important;
        font-size: 10px !important;
    }

    .small-text-max .row-data-1 {
        font-size: 10px;
    }

    .edit-plus-minus-icon {
        width: 28px !important;
        height: 28px !important;
    }

    /*.row-data-4 div {
        width: 50px !important;
        font-size: 13px;
    }*/
}

@media (max-width: 415px) {
    .row-data-2 {
        font-size: 11px;
    }
}

.right-menu {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
}

.right-menu-main {
    right: 0;
    top: 0;
    height: 100%;
    width: 100px;
    background-color: #002F6D;
    overflow-y: scroll;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

    .right-menu-main::-webkit-scrollbar {
        display: none;
    }

.right-menu-item {
    width: 100%;
    height: 90px;
    color: white;
    text-align: center;
    display: grid;
    padding: 15px;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

    .right-menu-item .item-icon {
        width: 25px;
        height: 22px;
    }

    .right-menu-item .item-name {
        color: #FFC159;
        font-size: 12px;
    }

.right-menu-hr {
    background-color: #FFC159;
    width: 75px;
    margin: 0 auto;
}

.right-menu-arrow-wrapper {
    background-color: #002F6D;
    width: 30px;
    height: 60px;
    margin-top: 50px;
    margin-right: -1px;
    color: #FFC159;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

    .right-menu-arrow-wrapper .right-menu-arrow {
        font-size: 20px;
        margin: 20px 10px;
    }

.menu-link {
    max-width: 65px;
}

.lobby-back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    color: white;
    width: 19px;
    height: 32px;
    background-repeat: no-repeat;
    z-index: 100;
}

.pregame-modal-body .display-wrapper {
    margin-bottom: 10px;
}

.modal-pregame-popup .start-btn-wrapper {
    margin: 10px 0;
    padding: 0 50px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.video {
    width: 100%;
    height: auto;
    min-height: 175px;
    border: 0;
    border-radius: 10px;
}

.video-name {
    font-size: 15px;
    color: #002e6c;
    font-family: 'Montserrat', sans serif;
    font-weight: 600;
}

.dashboard .carousel-indicators {
    bottom: 0;
}

    .dashboard .carousel-indicators li {
        width: 5px;
        height: 5px;
        margin-right: 5px;
        margin-left: 5px;
    }

.settings-radio-btn {
    margin-top: 4px;
    margin-right: 10px;
}

.settings-radio-btn-label {
    color: #002e6c;
    margin-left: 5px;
}

.image-sample {
    height: 220px;
    width: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.modal-body .game-container .game-info {
    margin: 0 auto 0.5rem;
}

.dashboard-v2-wrapper {
    padding: 20px 20px 0;
}

.vid-tutorial-top {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    margin-left: 25px;
}

.deposit-record-btn {
    color: #002C68;
    margin-right: 8px;
    margin-bottom: 18px;
    font-weight: bold;
}

.withdrawal-record-btn {
    color: #002C68;
    margin-right: 8px;
    margin-bottom: 10px;
    font-weight: bold;
}

.comm-history-btn {
    color: #002c68;
    margin: 20px 0px;
    font-weight: bold;
}

#custom-lobby .hot-games-label label {
    color: #002e6c;
}

.shareholder-nav {
    justify-content: space-around;
}

    .shareholder-nav .nav-item .nav-link.active {
        color: #ffc159 !important;
        border-bottom: 2px solid #ffc159;
    }

.claim-history-btn {
    color: #002c68;
    margin-right: 8px;
    font-weight: bold;
    margin-bottom: 10px;
}

.coming-soon-label {
    background-color: red;
    margin-right: 15px;
    font-size: 7px;
    text-transform: uppercase;
    border-radius: 10px;
    height: 10px;
    width: 100%;
    white-space: nowrap;
    font-weight: bold;
}

#contact-us .contact-list li {
    box-shadow: none;
}

.edit-plus-minus-icon {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
}

.row-data-1 {
    color: #002e6c;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    margin-right: 10px;
}

    .small-text-max .row-data-1 {
        margin-top: -15px;
        font-style: italic;
    }

#referral-detail-modern .edit-referral-table .row-data-1 {
    color: white;
}

#edit-downline-modern .edit-downline-table .row-data-1 {
    color: white;
}

.row-data-2 {
    color: #002e6c;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.row-data-2 .txt-1 {
    font-weight: bold;
    font-size: 14px;
}
.row-data-2 .txt-2 {
    font-style: italic;
}

#referral-detail-modern .edit-referral-table .row-data-2 {
    color: white;
}

#edit-downline-modern .edit-downline-table .row-data-2 {
    color: white;
}

.row-data-3 {
    color: #002e6c;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-right: 5px;
}

#referral-detail-modern .edit-referral-table .row-data-3 {
    color: white;
}

#edit-downline-modern .edit-downline-table .row-data-3 {
    color: white;
}

.row-data-4 div {
    width: 55px;
}

.row-data-max {
    background: #002e6c;
    font-weight: bold;
    color: white;
    border: 1px solid #002e6c;
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.small-text-max {
    font-size: 11px;
    display: flex;
}

.edit-percent-value {
    width: 60px;
    text-align: center;
    background: transparent;
    border: none;
    font-size: 14px;
}

.edit-percent-value:focus-visible {
    outline: none;
}

#referral-detail-modern .edit-percent-value {
    color: white;
}

#edit-downline-modern .edit-percent-value {
    color: white;
}

.edit-percent-value::-webkit-inner-spin-button {
    display: none;
}

.edit-referral-table {
    width: 100%;
}

.edit-downline-table {
    width: 100%;
}

.edit-referral-table .data-row {
    display: flex;
    padding: 10px;
    align-items: center;
}

.edit-downline-table .data-row {
    display: flex;
    padding: 10px;
    align-items: center;
}

.edit-referral-table .data-row.category:not(:nth-child(1)) {
    margin-top: 25px;
}

.edit-downline-table .data-row.category:not(:nth-child(1)) {
    margin-top: 25px;
}

.username-and-password {
    color: white;
    margin-left: 25px;
    margin-bottom: 50px;
}

.username-and-password .details.username {
    margin-bottom: 15px;
}

.username-and-password .details {
    display: flex;
    align-items: center;
}

.username-and-password .details-label {
    width: 100px;
}

.details.username .username-input {
    background-color: transparent;
    border: none;
    color: white;
    width: 125px;
}
.details.username .username-input:focus-visible {
    outline: none;
}

.details.password .password-input {
    background-color: transparent;
    border: none;
    color: white;
    width: 125px;
}
.details.password .password-input:focus-visible {
    outline: none;
}

.pregame-modal-body {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
}
    .pregame-modal-body::-webkit-scrollbar {
        display: none;
    }

#referral-detail .edit-percent-value {
    background: white;
    border-radius: 10px;
}

.refresh-password-btn {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-left: 20px;
}

.referral-copy-btn {
    cursor: pointer;
    width: 20px;
    position: absolute;
    right: 35px;
    margin-top: 5px;
}

.edit-referral-hr {
    background-color: grey;
    opacity: 0.3;
}

.bonusComm-option-select {
    height: 30px;
    border-radius: 15px;
    padding: 0 15px;
    margin: 0 15px;
    appearance: none;
    text-align: center;
    width: 70px;
    font-size: 13px;
    margin: 0 8px;
    background: white;
    color: black;
}
    .bonusComm-option-select:focus-visible {
        outline: none;
    }

.bonusComm-option-value {
    background: white;
    color: black;
}
    .bonusComm-option-value::-webkit-scrollbar {
        width: 5px;
    }

    .data-row.bg-color.classic {
        background-color: #c8c8e882;
        border-radius: 10px;
    }

.data-row.bg-color.modern {
    background-color: #043e8d;
    border-radius: 10px;
}

.data-row.bg-color-1.classic {
    background-color: #c8c8e882;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.data-row.bg-color-1.modern {
    background-color: #043e8d;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.data-row.bg-color-2.classic {
    background-color: #c8c8e882;
}

.data-row.bg-color-2.modern {
    background-color: #043e8d;
}

.data-row.bg-color-3.classic {
    background-color: #c8c8e882;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.data-row.bg-color-3.modern {
    background-color: #043e8d;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.send-tac-btn {
    position: absolute;
    right: 0;
    top: 12px;
    height: 25px;
    width: 70px;
    font-size: 10px;
    color: white;
    background: transparent;
    border: 0;
    border-radius: 10px;
}
    .send-tac-btn:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }

.phoneNum-input-wrapper {
    position: relative;
}

.tac-countdown {
    margin-top: -10px;
    font-size: 11px;
    color: #ffc159;
}

.input-card {
    background: #0E3467;
    box-shadow: 1px 3px 5px 0 #0000006b;
    padding: 5px 15px;
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.vertical-line {
    height: 20px;
    width: 1px;
}

.register-icon {
    width: 30px !important;
    height: 30px !important;
}

.register .input-card .form-group .invalid-feedback {
    padding-left: 0;
}

.register .input-card div#phoneContainer > div.separate-dial-code > input.customInput {
    padding: 0;
}

.register .input-card .flag-container .selected-flag .iti-flag {
    transform: scale(1.3);
    margin-right: 10px;
    margin-left: -20px;
}

.register-i8-icon {
    height: 51px;
    width: 75px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 20px;
}

.payment-option-card {
    background-color: #174F9C;
    color: #FFC159;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 1px 5px 0 #0000006b;
}

.active .nav-item .payment-option-card {
    background-color: #0E3467;
    outline: 1px solid rgb(255 255 255 / 30%);
}


.payment-option-card .options {
    display: flex;
    justify-content: space-evenly;
}

.payment-option-card .options .option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 5px;
}

.payment-option-card .options .option.active {
    box-shadow: 0px 2px 5px #00000094;
    border-radius: 10px;
    background-color: #005ABE;
}

#deposit .payment-option-card .options .option.active {
    background-color: #FFC159;
}

.payment-option-card .options .option .option-image {
    background-color: #f0f8ff;
    color: #234c82;
    height: 60px;
    width: 60px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-weight: bolder;
}

#deposit .payment-option-card .options .option .option-image {
    box-shadow: 0px 2px 2px #00000059;
}

.payment-option-card .options .option.active .option-text {
    color: #FFC159;
}

#deposit .payment-option-card .options .option .option-text {
    font-weight: 600;
}

#deposit .payment-option-card .options .option.active .option-text {
    color: #002E6C;
}

.payment-option-card .options .option .option-text {
    color: #FFFFFF;
    font-weight: lighter;
    margin-top: 5px;
}

.payment-option-card .normal-deposit .option-1 .option-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.payment-option-card .instant-deposit .option-2 .option-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.payment-option-card .instant-deposit .option-4 .option-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

#deposit .active .nav-item .payment-option-card {
    background-color: #E0EDFF;
    box-shadow: 0px 1px 5px 0 #0000006b;
}

#deposit .payment-option-card {
    background-color: #F2F8FF;
    color: #002E6C;
    box-shadow: none;
}

.withdrawal-fullName-input {
    outline: none;
    border: none;
    border-bottom: 2px solid #002e6c;
    margin-bottom: 20px;
    font-size: 15px;
}

.react-simple-keyboard {
    margin-top: 10px;
}

.shareholder-my-income {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.my-income-card {
    text-align: center;
    padding: 5px;
    border-radius: 15px;
}

.my-income-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shareholder-my-income .content-title {
    font-weight: bold;
    font-size: 12px;
    color: #002e6c;
}

@media (max-width: 461px) {
    .shareholder-my-income .content-title {
        font-size: 10px !important;
    }
}

@media (max-width: 403px) {
    .shareholder-my-income .content-title {
        font-size: 8px !important;
    }
}

.shareholder-my-income .content-value {
    color: #002e6c;
    font-size: 15px;
}

.has-downline {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.offline-message {
    color: white;
    text-align: center;
    padding: 20px 60px;
}

.card-wrapper {
    padding: 3px;
    background: linear-gradient(100deg, #764E1B, #D7AA2D, #FFFB9A, #D7AA2D, #FFFB9A, #D7AA2D, #764E1B);
    border-radius: 15px;
}

.shareholder-my-income .card-wrapper {
    width: 30%;
}

.v2-um-tag {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
}
    .v2-um-tag.game-category-label {
        left: 0;
        top: 0;
        background-color: black;
        opacity: 0.8;
    }

.v2-um-tag-icon {
    background-image: url(/static/media/dashboardv2_um.ec3690dd.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 20px;
    left: 15px;
    width: 60px;
    height: 60px;
}

#rewards-homepage .banner {
    min-height: 150px;
}

#rewards-homepage .carousel-img {
    height: 220px;
    object-fit:cover;
}

.rewards-calendar-day {
    display: flex;
    flex-direction: column;
    height: 60px;
    width: 40px;
    padding: 5px 0;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

#rewards-homepage .rewards-value {
    height: 100%;
}

    #rewards-homepage .rewards-value.left {
        background-color: rgb(2, 37, 84);
        text-align: center;
        font-size: 15px;
        width: 30%;
        padding: 25px 0;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    #rewards-homepage .rewards-value.right {
        width: 70%;
    }

    #rewards-homepage .category-icon {
        width: 30px;
        margin-right: 50px;
    }

.rewards-calendar {
    color: #002E6C;
    font-weight: bold;
    font-size: 16px;
}

.rewards-calendar-month {
    font-size: 18px;
}

.rewards-calendar-week {
    font-size: 12px;
    font-weight: normal;
}

.rewards-detail-banner {
    height: 205px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.rewards-detail-category {
    display: flex;
    justify-content: space-evenly;
    margin-top: -25px;
    z-index: 999;
}

.rewards-detail-category .category-icon-wrapper {
    background-color: #ffc159;
    text-align: center;
    padding: 8px;
    user-select: none;
    margin: 0 auto;
}

.rewards-detail-category .category-icon-wrapper .category-icon {
    width: 100%;
    height: 100%;
}

.rewards-detail {
    padding: 0 20px;
    padding-bottom: 50px;
}

.rewards-detail .category-name {
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
    user-select: none;
}

.master-product-grid {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 2fr;
    justify-items: center;
}

    .master-product-grid .product-image {
        width: 100px;
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }
    @media (max-width: 341px) {
        .master-product-grid .product-image {
            width: 50px !important;
            height: 50px !important;
        }
        .master-product-grid .product-rate {
            font-size: 10px !important;
        }
    }
    @media (max-width: 476px) {
        .master-product-grid .product-image {
            width: 70px;
            height: 70px;
        }
        .master-product-grid .product-rate {
            font-size: 13px;
        }
    }

    .master-product-grid .product-rate {
        color: #002E6C;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .master-product-grid .card-wrapper {
        margin-bottom: 20px;
    }

.referral-link-wrapper .form-control {
    padding-right: 50px;
}

.downline-nav .nav-link {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings-display-hp {
    border-radius: 20px;
    background-color: #aaaaaa;
    width: 100%;
    padding: 5px 10px;
}

.slick-slide {
    text-align: -webkit-center;
}
/*.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-initialized .slick-slide {
    display: block;
    margin: 0 10px;
    width: 55px !important;
}

.slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 200px !important;
}

.slick-slider .slick-list, .slick-slider .slick-track {
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}*/

.popup-bottom-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100% - 275px);
    overflow-y: scroll;
    padding-bottom: 30px;
}
    .popup-bottom-wrapper::-webkit-scrollbar {
        display: none;
    }

.tooltip-text {
    visibility: hidden;
    position: absolute;
    width: fit-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    z-index: 1;
}

.refresh-password-btn:hover .tooltip-text {
    visibility: visible !important;
    right: 15px;
}

#game-page .user-info {
    padding: 0 10px;
    height: 50px;
    align-items: center;
}

#game-page .user-info-left {
    width: 50%;
}

#game-page .user-info-right {
    display: flex;
    align-self: center;
    width: 50%;
    justify-content: flex-end;
}

#game-page .wallet-and-commission {
    height: 60px;
}

#game-page .btn.btn-icon {
    font-size: 20px;
}

#game-page .btn.btn-icon:hover {
    background: linear-gradient(180deg,#ffcf7d,#fe9f00);
}

.bottom-drawer {
    display: flex;
    justify-content: center;
    background-color: #ffc159;
    cursor: move;
    user-select: none;
}

.top-drawer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #ffc159;
    cursor: pointer;
}

.more-games {
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    max-height: 100vh;
    background-color: black;
    opacity: 0.9;
    z-index: 999;
    overflow-y: scroll;
}
    .more-games::-webkit-scrollbar {
        display: none;
    }

    .more-games .lobby-games-img {
        width: 120px;
        height: 120px;
        position: relative;
    }

    .more-games .hot-games-tile {
        max-width: 120px;
    }

    .more-games .hot-games-name {
        padding: 0 5px;
        padding-bottom: 5px;
    }

.more-games-content {
    display: flex;
    flex-direction: column;
    padding: 0 20px 10px 20px;
}

    .more-games-content .hot-games-text {
        color: white;
        height: 30px;
        line-height: 14px;
    }
    .more-games .game-row {
        margin: 0;
    }

.game-drawer-dark-bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.5;
}

.game-tile-background {
    width: 120px;
    height: fit-content;
    top: 45px;
    border-radius: 13px;
    background-color: rgb(255 255 255 / 20%);
    backdrop-filter: blur(8px);
}

.more-games .currently-playing-label {
    width: 100%;
    position: absolute;
    top: 50px;
    font-size: 10px;
    background-color: black;
    color: #ffc159;
    padding: 7px 0;
}

.more-games .btn.btn-block.btn-brand {
    outline: none;
    border: none;
}

.home_page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

#rewards-homepage .accordion-button {
    width: 100%;
    display: flex;
    padding: 0;
    background: #03306D;
    border: none;
    border-radius: 15px;
    align-items: center;
    color: white;
}

#rewards-homepage .accordion-collapse {
    padding: 10px;
    background: #03306D;
    border-radius: 15px;
}

.dropdown-arrow {
    width: 10px;
    height: 10px;
    border-bottom: 3px solid rgb(255, 255, 255);
    border-right: 3px solid rgb(255, 255, 255);
    border-radius: 2px;
    transition: all 0.3s ease 0s;
    transform: rotate(-45deg);
}

    .dropdown-arrow.active {
        transform: rotate(45deg);
    }

.login-browser-icon.social-media {
    width: 20px;
    margin: 5px 13px 0 0;
}

.display-flex {
  display: flex;
}

/*here onwards start of modern-style css*/
    .modern-header {
        background: transparent;
        border-radius: 0 !important;
        position: relative;
    }

#modern.transaction-nav .nav-link {
    color: white;
}

    #modern.transaction-nav .nav-link.active {
        color: #ffc159;
    }

    #modern.transaction-nav .nav-link:not([href]):hover {
        color: white;
    }

    #modern.transaction-nav .nav-link.active:not([href]):hover {
        color: #ffc159;
    }

#deposit-modern .form-group label {
    color: white;
}

#withdrawal-modern .form-group label {
    color: white;
}

#transfer-modern .form-group label {
    color: white;
}

#internal-transfer-modern .form-group label {
    color: white;
}

#deposit-modern .deposit-record-btn {
    font-size: 12px;
    color: white;
}

#withdrawal-modern .withdrawal-record-btn {
    color: white;
}

#deposit-modern .col-form-label {
    color: white;
}

#date-range-filter-modern .text {
    color: white;
}

#date-range-filter-modern .selection-item {
    background-color: white;
}

#bonus-history-modern .col-form-label {
    color: white;
}

#game-history-modern .col-form-label {
    color: white;
}

.pool-result-modern .card-header {
    color: white;
}

.pool-result-modern tbody th, .pool-result-modern thead th {
    color: white;
}

.pool-result-modern tbody td, .pool-result-modern thead td {
    color: white;
}

p.f-w-800.text-brand.modern {
    color: white;
}

.downline-nav.modern .nav-item .nav-link {
    color: white;
}

    .downline-nav.modern .nav-item .nav-link:hover {
        color: white;
    }

.downline-games-slider-modern {
    margin-top: 10px;
}

.downline-games-modern.form-group label {
    color: white;
}

.downline-games-slider-modern .form-group label {
    color: white !important;
}

.downline-games-slider-modern .form-group .rangeslider .rangeslider__handle {
    background: #ffc159;
    color: #002e6c;
}

.downline-games-slider-modern .form-group .rangeslider .rangeslider__fill {
    background: #ffc159;
}

#referral-detail-modern .col-form-label {
    color: white;
}

.product-modern label {
    color: white !important;
}

.product-modern .form-group .rangeslider .rangeslider__handle {
    background: #ffc159;
    color: #002e6c;
}

.product-modern .form-group .rangeslider .rangeslider__fill {
    background: #ffc159;
}

.product-modern .form-group .rangeslider {
    background: #dbdbdb;
}

.table-kpi.modern tbody {
    color: white;
}

#comm-history-modern .comm-history-btn {
    color: white;
}

#downline-details-modern span {
    color: white;
}

#downline-details-modern .form-group label {
    color: white !important;
}

#downline-details-modern .form-group .rangeslider .rangeslider__handle {
    background: #ffc159;
    color: #002e6c;
}

#downline-details-modern .form-group .rangeslider .rangeslider__fill {
    background: #ffc159;
}

#downline-group-modern p {
    color: white;
}

#downline-group-modern .col-form-label {
    color: white !important;
}

#downline-group-modern .no-group-name {
    color: white;
}

#custom-lobby-modern .hot-games-label label {
    color: white;
}

#custom-lobby-modern .all-games .games-lobby .nav-item .nav-link {
    color: white;
}

    #custom-lobby-modern .all-games .games-lobby .nav-item .nav-link.active {
        color: #ffc159;
    }

#video-tutorial-modern .video-name {
    color: white;
}

.tnc-container.modern {
    color: white;
}

    .tnc-container.modern::-webkit-scrollbar {
        background: #002e6c;
    }

    .tnc-container.modern::-webkit-scrollbar-thumb {
        background: #ffc159;
    }

    .tnc-container.modern .tnc-logo {
        background-color: transparent;
    }

    .tnc-container.modern .tnc-label {
        color: white;
    }

.settings-page.modern .form-group label {
    color: white;
}

.settings-page.modern .settings-options-text {
    color: white;
}

.settings-page.modern .settings-radio-btn-label {
    color: white;
}

.settings-page.modern .change-pin-label {
    color: white;
}

.settings-page.modern input.pincode-input-text {
    border-bottom: 2px solid white !important;
}

.shareholder-nav.modern .nav-item .nav-link {
    color: white;
}

#shareholder-downline-modern .text-brand {
    color: white;
}

#shareholder-claim-modern .claim-history-btn {
    color: white;
}

#shareholder-detail-modern .form-group label {
    color: white !important;
}

#shareholder-detail-modern span.pl-4 {
    color: white;
}

#shareholder-detail-modern .form-group .rangeslider .rangeslider__handle {
    background: #ffc159;
    color: #002e6c;
}

#shareholder-detail-modern .form-group .rangeslider .rangeslider__fill {
    background: #ffc159;
}

.modal-content.edit-downline-modal.modern {
    background: #002e6c;
}

    .modal-content.edit-downline-modal.modern .modal-header {
        color: white;
    }

.edit-downline-modal.modern .close {
    color: white;
}

.modal-content.modern .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
    border-radius: 10px;
}

.modal-content.modern .modal-body::-webkit-scrollbar-thumb {
    background-color: #ffc159;
}

.modal-content.modern .modal-body::-webkit-scrollbar-corner {
    background: transparent;
}

#news-details-modern .news-detail-title {
    color: white;
}

#news-details-modern .text-brand {
    color: white;
}

.game-search-box {
    padding: 20px 61px;
    position: absolute;
    bottom: -14px;
    width: 100%;
}

#shareholder-downline-modern .font-thick {
    color: white;
}

#shareholder-downline-modern .dynamic-table-spinner {
    color: white;
}

#custom-lobby-modern .font-thick {
    color: white;
}

#custom-lobby-modern .dynamic-table-spinner {
    color: white;
}

#downline-modern .font-thick {
    color: white;
}

#downline-modern .dynamic-table-spinner {
    color: white;
}

#custom-lobby-modern input.form-control::placeholder {
    color: #6c757d;
}

#custom-lobby-modern .hot-games-text {
    color: white;
}

.wallet-info.modern {
    background-color: #002e6c;
}

.wallet-info.modern .modal-title {
    color: #ffc159;
}

.wallet-info.modern .modal-header .close {
    color: #ffc159;
}

#referral-detail-modern .row-data-max {
    background: white;
    color: #002e6c;
}

#edit-downline-modern .row-data-max {
    background: white;
    color: #002e6c;
}

.dashboard .banner {
    min-height: 150px;
}

.game-row tr {
    width: 100%;
}

#referral-detail-modern .data-row.category .row-data-max {
    background: rgb(255, 193, 89);
}

#referral-detail-modern .data-row.category .edit-percent-value {
    color: rgb(255, 193, 89);
}

#edit-downline-modern .data-row.category .row-data-max {
    background: rgb(255, 193, 89);
}

#edit-downline-modern .data-row.category .edit-percent-value {
    color: rgb(255, 193, 89);
}

.send-tac-btn {
    background-color: rgb(255, 193, 89);
    color: #002e6c;
    font-weight: 900;
}

#date-range-filter-modern .date-selection-wrapper .selection-item.active {
    color: #ed9500;
}

.popup-bottom-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 225px);
}

/* Deposit payment method*/
.accordion-item {
    background: #4A73AA;
    border-radius: 10px;
    margin-bottom: 15px;
}

#rewards-homepage .accordion-item {
    background: none;
    border-radius: 15px;
}

.accordion-container .accordion-header .accordion-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #F0F8FF;
    border-radius: 10px;
    border: 0;
    width: 100%;
    text-align: left;
    padding-left: 17px;
}

.accordion-container .accordion-header label {
    font-size: 16px;
    color: #0C2756 !important;
}

.accordion-container .accordion-header {
    margin-bottom: 12px;
}

.accordion-container .accordion-body .nav .nav-link {
    margin: 7px 0 14px;
    padding: 0;
}

.accordion-container .accordion-button:after {
    font-family: "Font Awesome 5 Free";
	font-weight: 900;
    content: '\f078';
    padding-right: 19px;
}

.accordion-container .accordion-button:not(.collapsed):after {
    content: '\f077';
}

/* NormalDeposit */
.deposit-form{
    margin-left:20px;
    margin-right:20px;
    padding-bottom: 20px;
}

.deposit-table .border-top{
    border-top-width:0px !important;
}

.deposit-table .border-bottom{
    border-bottom-width:0px !important;
}

.bank-text{
    color:#002E6C;
}

.float-right-icon {
    float: right;
}

.deposit-table .custom-checkbox{
    text-align: center;
}

.deposit-table .custom-checkbox input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 15px;
    height: 15px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #012C65;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  .deposit-table .custom-checkbox input[type="radio"]:checked {
    background-color: #012C65;
    border: none;
  }

.deposit-table {
    border-spacing: 0; 
    border-collapse: separate;
    border: none !important;
    font-weight: bold;
}

.deposit-table td {
    padding-left: 20px !important;
    padding-right: 20px !important;
    border: 1px solid #00000029 !important;
}

.deposit-table td:nth-child(n+1)  {
    border-bottom-width:0px !important;
    background-color: #FFFFFF;
}

.deposit-table tr:nth-last-child(1) td:nth-last-child(1), tr:nth-last-child(1) td:first-child {
    border-bottom-width:1px !important;
}

.deposit-table tr:last-child td:last-child {
    border-bottom-right-radius:20px;
}

.deposit-table tr:last-child td:first-child {
    border-bottom-left-radius:20px;
}

.deposit-table tr:first-child td:first-child {
    border-top-left-radius:20px;
}

.deposit-table tr:nth-child(n+1) td:first-child {
    border-right-width:0px !important;
    background-color: #FFFFFF;
}

.deposit-table tr:first-child td:last-child {
    border-top-right-radius:20px
}

.custom-button {
    background: #D4E6FF;
    width: 90px;
    border: 1px solid #002E6C;
    color: #002E6C;
    font-weight: bold;
}

.amount-row {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 15px 0;
    text-align: center;
    padding: 13px 0px;
}

.custom-button:not(:disabled):not(.disabled):active{
    background-color: #adc4e4;
}

.deposit.input-group {
    box-shadow: none !important;
    background: #ffffff !important;
}

.close-button {
    padding:10px;
}

.deposit-note {
    padding-left: 20px;
    padding-right: 20px;
}

/*end of modern-style css*/